<template>
  <div id="root-patient-creation">
    <form @submit.prevent="savePatient">
      <h2
        class="pb-3"
        align="center"
      >
        Créer un patient
      </h2>
      <div class="field-block">
        <label>
          <span class="block">Nom</span>
          <input
            v-model="lastName"
            type="text"
            class="text-input"
            @input="(e) => onInput(e, lastName, false)"
          >
          <p
            v-for="(error, index) in errors.lastName"
            :key="index"
            class="error-modal"
          >
            {{ error }}
          </p>
        </label>
      </div>
      <div class="field-block">
        <label>
          <span class="block">Prénom</span>
          <input
            v-model="firstName"
            type="text"
            class="text-input"
            @input="(e) => onInput(e, firstName, false)"
          >
          <p
            v-for="(error, index) in errors.firstName"
            :key="index"
            class="error-modal"
          >
            {{ error }}
          </p>
        </label>
      </div>
      <div class="field-block">
        <label>
          <span class="block">Date de naissance</span>
          <input
            v-model="birthDate"
            type="date"
            class="text-input"
            @input="(e) => onInput(e, birthDate, false)"
          >
          <p
            v-for="(error, index) in errors.birthDate"
            :key="index"
            class="error-modal"
          >
            {{ error }}
          </p>
        </label>
      </div>
      <div class="field-block">
        <label>
          <span class="block">Pays de résidence</span>
          <input
            v-model="reside"
            type="text"
            class="text-input"
            @input="(e) => onInput(e, reside, false)"
          >
          <p
            v-for="(error, index) in errors.reside"
            :key="index"
            class="error-modal"
          >
            {{ error }}
          </p>
        </label>
      </div>
      <div class="field-block">
        <div>
          <label>
            <input
              v-model="gender"
              type="radio"
              name="gender"
              value="M"
              @change="(e) => onInput(e, gender, true)"
            >
            Homme
          </label>
        </div>

        <div>
          <label>
            <input
              v-model="gender"
              type="radio"
              name="gender"
              value="F"
              @change="(e) => onInput(e, gender, true)"
            >
            Femme
          </label>
        </div>
        <p
          v-for="(error, index) in errors.gender"
          :key="index"
          class="error-modal"
        >
          {{ error }}
        </p>
      </div>
      <div class="field-block">
        <label>
          <span class="block">Poids (kg)</span>
          <input
            v-model="weight"
            type="number"
            class="text-input"
            @input="(e) => onInput(e, weight, false)"
          >
          <p
            v-for="(error, index) in errors.weight"
            :key="index"
            class="error-modal"
          >
            {{ error }}
          </p>
        </label>
      </div>
      <div class="field-block">
        <label>
          <span class="block">Taille (cm)</span>
          <input
            v-model="height"
            type="number"
            class="text-input"
            @input="(e) => onInput(e, height, false)"
          >
          <p
            v-for="(error, index) in errors.height"
            :key="index"
            class="error-modal"
          >
            {{ error }}
          </p>
        </label>
      </div>
      <div>
        <DesignButton
          id="create-patient-validate"
          text="Valider"
        />
      </div>
    </form>
  </div>
</template>

<script setup>
import { useStore } from 'vuex'
import { ref } from 'vue'
import DesignButton from '@/components/DesignButton.vue'
import { useRouter } from 'vue-router'

const store = useStore()
const router = useRouter()

const lastName = ref("")
const firstName = ref("")
const birthDate = ref("")
const reside = ref("")
const gender = ref("")
const weight = ref(0)
const height = ref(0)

const errors = ref({})

function checkForm() {
  const requiredField = 'Ce champ est obligatoire'
  errors.value = {
    lastName: [],
    firstName: [],
    birthDate: [],
    reside: [],
    gender: [],
    weight: [],
    height: []
  }

  if (lastName.value === "") {
    errors.value.lastName.push(requiredField)
  }
  if (firstName.value === "") {
    errors.value.firstName.push(requiredField)
  }
  if (birthDate.value === "") {
    errors.value.birthDate.push(requiredField)
  }

  const testDate = new Date(birthDate.value)
  if (testDate.toString() === 'Invalid Date') {
    errors.value.birthDate.push('Date invalide')
  }
  if (reside.value === "") {
    errors.value.reside.push(requiredField)
  }
  if (gender.value === "") {
    errors.value.gender.push(requiredField)
  }
  if (weight.value === "") {
    errors.value.weight.push(requiredField)
  }
  if (weight.value <= 0) {
    errors.value.weight.push('La valeur doit être un nombre entier positif')
  }
  if (height.value === "") {
    errors.value.height.push(requiredField)
  }
  if (height.value <= 0) {
    errors.value.height.push('La valeur doit être un nombre entier positif')
  }
}

async function savePatient(event) {
  checkForm()

  for (const err of Object.values(errors.value)) {
    if (err.length > 0) {
      return
    }
  }

  const patientObj = {
    lastName: lastName.value,
    firstName: firstName.value,
    birthDate: birthDate.value,
    reside: reside.value, 
    gender: gender.value,
    weight: weight.value,
    height: height.value
  }

  if (event.isTrusted) {
    await store.dispatch('patient/addPatient', {
      patient: patientObj
    })
  }
  store.dispatch('ws/sendEvent', {event: event})
  router.push({ name: 'Home' })
  store.dispatch('ws/collaborativeEventTreated')
}

function onInput(event, text, radio) {
  store.dispatch('ws/sendEvent', {
    event: event,
    params: {
      text: text,
      change: radio === true
    }
  })

  store.dispatch('ws/collaborativeEventTreated')
}

</script>

<style scoped>
#root-patient-creation {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

#root-patient-creation > form {
  border: 1px solid var(--color-shadow);
  padding: 15px;
  border-radius: 10px;
  width: 350px;
}

#create-patient-validate {
  margin: unset;
  width: 100%;
}

.text-input {
  @apply py-1 px-1;
  width: 100%;
  height: 2.5rem;
  box-sizing: border-box;
}

label > span {
  @apply mb-1;
  font-size: 1.1rem;
}

label {
  font-size: 1.2rem;
}

.field-block {
  @apply mb-6;
}
</style>