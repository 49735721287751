<template>
  <div id="state-eyediag">
    <div class="flex flex-row">
      <div>
        <p id="state-hierarchy-name">
          {{ classifShortLabel }}
        </p>
      </div>
      <div
        id="state-eyediag-r-container"
        class="flex flex-col justify-center"
      >
        <p>{{ typeContent }}</p>
        <p
          @mouseover="onOverPath"
          @mouseleave="onLeavePath"
        >
          {{ hoverPath ? fullHierarchyPath : lastHierarchyPath }}
        </p>
      </div>
    </div>
    <div
      v-if="isInPopulational"
      @mouseover="onOverFocus"
      @mouseleave="onLeaveFocus"
    >
      <div v-if="hoverFocus === false">
        <p>{{ currentFocus }}</p>
      </div>
      <div v-else>
        <p>
          <span
            v-for="(focus, index) in fullFocusPath"
            :key="index"
          >
            <span v-if="index !== 0">
              {{ ' > ' }}
            </span>
            <span
              class="underline"
              @click="(e) => onChangeFocus(e, index)"
            >
              {{ focus.label }}
            </span>
          </span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import * as eyediagMode from '@/shared/enums/eyediagMode.js'
import * as mutationTypes from '@/store/mutations-types.js'
import utils from "@/libraries/utils.js"
import reloadDataTypes from '@/shared/enums/reload_data_types.js'
import * as Hierarchy from '@/shared/enums/hierarchy.js'

export default {
  name: 'EyediagState',
  data: () => ({
    hoverPath: false,
    hoverFocus: false
  }),
  computed: {
    ...mapGetters({
      historySections: 'historySections',
      eyediagMode: 'mode',
      isInPopulational: 'isInPopulational',
      hierarchy: 'hierarchy'
    }),
    previousMode() {
      return this.$store.state.previousMode
    },
    fullFocusPath() {
      const focus = []
      const addElementPath = (mode) => {
        if (mode.type === eyediagMode.mode.POPULATIONAL) {
          focus.push({
            label: 'Cohorte',
            mode: mode
          })
        } else {
          focus.push({
            label: mode.options.cohortFocusContent === eyediagMode.populationalOptions.focusContent.RSS
              ? `RSS (${mode.options.cohortFocusParams.RSS})`
              : `RUM (${mode.options.cohortFocusParams.idRum})`,
            mode: mode
          })
        }
      }

      for (const mode of this.previousMode) {
        addElementPath(mode)
      }
      addElementPath(this.eyediagMode)
      return focus
    },
    currentFocus() {
      let last = ''

      if (this.fullFocusPath.length > 0) {
        last = this.fullFocusPath[this.fullFocusPath.length - 1].label
      }

      return last
    },
    indexHistorySection() {
      return this.$store.state.indexHistorySection
    },
    classifShortLabel() {
      switch (this.$store.state.hierarchy.id) {
      case 'UM':
        return 'UM.'
      case 'GHM':
        return 'GHM.'
      case 'Welcoop':
      case 'Welcoop2':
      case 'Welcoop3':
        return 'Pharma.'
      default:
        return 'Cliniq.'
      }
    },
    typeContent() {
      if ([eyediagMode.mode.PATIENT, eyediagMode.mode.UNIVERSAL].includes(this.eyediagMode.type)) {
        return 'Evénements'
      } else {
        if (this.eyediagMode.options.content.includes(eyediagMode.populationalOptions.content.GHM)) {
          return 'GHM'
        } else if (
          this.eyediagMode.options.content.includes(eyediagMode.populationalOptions.content.MAIN_DIAGNOSIS)
          && this.eyediagMode.options.content.includes(eyediagMode.populationalOptions.content.ASSOCIATED_DIAGNOSIS)
        ) {
          return 'Tous les Evénements'
        } else if (this.eyediagMode.options.content.includes(eyediagMode.populationalOptions.content.MAIN_DIAGNOSIS)) {
          return 'DP'
        } else {
          return 'Evénements Associés'
        }
      }
    },
    fullHierarchyPath() {
      if (this.historySections.length === 0) {
        return ''
      }
      const hierarchyId = this.hierarchy.id
      let path = ''

      //Pour rester cohérent, il faut que le chemin soit celui d'une seule et même hiérarchie. Par exemple ne pas mélanger la hiérarchie classique avec la hiérarchie entrée de l'info diabétique
      for (let i = this.indexHistorySection; i >= 0 && this.historySections[i].hierarchy.id === hierarchyId; i--) {
        if (this.historySections[i].sections.length === 1) {
          path = ` > ${this.historySections[i].sections[0].name}${path}`
        }
      }

      path = `Global Cats ${Hierarchy.hierarchyLabel[hierarchyId] || hierarchyId}${path}`
      return path
    },
    lastHierarchyPath() {
      const fullPath = this.fullHierarchyPath || ''
      const paths = fullPath.split('>')
      let last = ''

      if (paths.length > 0) {
        last = paths[paths.length - 1]
      }
      last = last.trim()

      return last
    }
  },
  methods: {
    ...mapActions({
      sendEvent: 'ws/sendEvent',
      collaborativeEventTreated: 'ws/collaborativeEventTreated'
    }),
    onOverPath(event) {
      this.sendEvent({ event: event })
      this.hoverPath = true
      this.collaborativeEventTreated()
    },
    onLeavePath(event) {
      this.sendEvent({ event: event })
      this.hoverPath = false
      this.collaborativeEventTreated()
    },
    onOverFocus(event) {
      this.sendEvent({ event: event })
      this.hoverFocus = true
      this.collaborativeEventTreated()
    },
    onLeaveFocus(event) {
      this.sendEvent({ event: event })
      this.hoverFocus = false
      this.collaborativeEventTreated()
    },
    async onChangeFocus(event, index) {
      //Aucun intéret de tout rafraichir, l'utilisateur est déjà positionné sur le bon mode, puisque c'est le dernier de l'historique
      if (index === this.fullFocusPath.length - 1) {
        return
      }
      this.sendEvent({event: event})
      
      //Retour sur la cohorte, donc en enleve le filtrage par DP pour rentre la lecture plus simple à l'utilisateur
      if (index === 0) {
        this.$store.commit(`event/common/${mutationTypes.SET_EVENT_FILTERS}`, {})
        this.$store.commit(`${mutationTypes.UPDATE_LABEL_FILTERS}`, '')
      }

      this.$store.commit(`${mutationTypes.SET_EYEDIAG_MODE}`, this.fullFocusPath[index].mode)
      for (let i = 0; i < this.fullFocusPath.length - index; i++) {
        this.$store.commit(`${mutationTypes.REMOVE_PREVIOUS_MODE}`)
      }
      await this.$store.dispatch('circle/getDataRepresentation', {
        unitPerCircle: this.$store.state.circle.unitPerCircle,
        periodUnit: this.$store.state.circle.periodUnit,
        reloadDataTypes: reloadDataTypes.CATEGORISATION
      })
      utils.onGlobalAnimationEnd(this.$store, () => {
        this.collaborativeEventTreated()
      })
    }
  }
}
</script>

<style scoped>
#state-eyediag {
  position: absolute;
  bottom: 35px;
  left: 50px;
}

#state-eyediag * {
  font-family: var(--font-secondary-2);
}

#state-hierarchy-name {
  font-size: 3.3rem;
  font-weight: 100;
  letter-spacing: 0.03em;
}

#state-eyediag-r-container {
  margin-left: 1.5rem;
  font-size: 1.3rem;
}
</style>
