import * as mutationTypes from '../mutations-types.js'
import ErrorManager from '@/libraries/ErrorManager.js'
import EyeFetch from '@/libraries/EyeFetch.js'
import dataSourceComposition from '@/shared/enums/dataSourceComposition.js'

const getDefaultState = () => {
  return {
    dataSourceComposition: dataSourceComposition.SINGLE,
    /**
     * Identifiant du patient actuellement visualisé par l'utilisateur
     * @type {Number}
     */
    idPatient: null,
    /**
     * Contient les informations du patient
     * @type {Object}
     */
    patient: null,
    patientNotes: []
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    dataSourceComposition: state => state.dataSourceComposition,
    patientData: state => state.patient,
    patientNotes: state => state.patientNotes
  },
  mutations: {
    [mutationTypes.UPDATE_DATA_SOURCE_COMPOSITION] (state, payload) {
      state.dataSourceComposition = payload
    },
    /**
     * Permet de mettre à jour les informations du patient 
     * @param {Objet} payload
     * @method
     * @public
     */
    [mutationTypes.UPDATE_PATIENT_DATA](state, payload) {
      state.patient = payload
    },
    /**
     * Permet de mettre à jour l'identifiant du patient actuellement visualisé par l'utilisateur
     * @param {Number} payload
     * @method
     * @public
     */
    [mutationTypes.SET_ID_PATIENT](state, payload) {
      state.idPatient = payload
    },
    [mutationTypes.SET_PATIENT_NOTES](state, payload) {
      state.patientNotes = payload
    }
  },
  actions: {
    /**
     * Permet de reset le state du module
     * @method
     * @public
     */
    resetState(context) {
      Object.assign(context.state, getDefaultState())
    },
    /**
     * Permet d'obtenir les informations du patient
     * @method
     * @public
     */
    async getPatientData(context) {
      try {
        const response = await EyeFetch(this,
          `${process.env.VUE_APP_SERVER_BASE_URL}/fhir/patient/${context.state.idPatient}`,
          {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify({
              mode: context.rootState.mode
            }),
          }
        )

        if (!response.ok) {
          ErrorManager.requestError(response, {origin: 'patient/getPatientData', params: null})
          return 
        }

        const patientData = await response.json()
        context.commit(mutationTypes.UPDATE_PATIENT_DATA, patientData.patientRessources)
      } catch (err) {
        console.error(err)
        ErrorManager.fetchError(err)
      }
    },
    async getAllPatientNotes(context) {
      const params = new URLSearchParams()
      params.append('idPatient', context.state.idPatient)

      let response = await EyeFetch(this,
        `${process.env.VUE_APP_SERVER_BASE_URL}/patient/note/all?${params}`,
        {
          method: 'GET',
          credentials: 'include'
        }
      )

      if (!response.ok) {
        return
      }
      response = await response.json()

      for (const patientNote of response) {
        patientNote.State = JSON.parse(patientNote.State)
      }

      context.commit(mutationTypes.SET_PATIENT_NOTES, response)
    },
    async getPatientsList(context) {
      try {
        let response = await EyeFetch(this,
          `${process.env.VUE_APP_SERVER_BASE_URL}/patient/list`,
          {
            method: 'GET',
            credentials: 'include'
          }
        )
  
        if (!response.ok) {
          return []
        }
        response = await response.json()
        return response
      } catch (err) {
        console.error(err)
        return []
      }
    },
    async addPatient(context, {patient}) {
      try {
        await EyeFetch(this,
          `${process.env.VUE_APP_SERVER_BASE_URL}/patient/`,
          {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(patient)
          }
        )
      } catch (err) {
        console.error(err)
      }
    }
  }
}