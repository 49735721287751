<template>
  <div>
    <div
      v-for="(event, index) in events"
      :key="index"
    >
      <ListItem
        :event="event"
      />
    </div>
    <!-- <ul>
      <li
        v-for="(event, index) in events"
        :key="index"
        class="mb-2"
      >
        <p>{{ event.defaultLabel }}</p>
        <p>{{ event.onsetDateTime.slice(0, 10) }}</p>
      </li>
    </ul> -->
  </div>
</template>

<script>
export default {
  name: 'LegendPie'
}
</script>

<script setup>
import { useStore } from 'vuex'
import { computed } from 'vue'
import EventManager from '@/libraries/EventManager'
import ListItem from '@/components/event/ListItem.vue'

const store = useStore()

const pieHoveredSections = computed(() => store.getters['pie/pieHoveredSections'])
const displayedCircles = computed(() => store.getters['circle/displayedCircles'])
const current = computed(() => {
  return displayedCircles.value.find(circle => circle.active === true)
})

const events = computed(() => {
  if (current.value && pieHoveredSections.value.length > 0) {
    let events = EventManager.extractEventsFromCircle([current.value])
    const idPie = pieHoveredSections.value[pieHoveredSections.value.length - 1].id
    events = events.filter(event => {
      if (event.parentSection === idPie) {
        event.r = EventManager.severityRadius[Math.round(event.severity)]
        return true
      }
      return false
    })
    return events
  }
  return []
})
</script>