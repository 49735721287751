import store from '@/store/index.js'
import errorTypes from '@/enums/errorTypes'
import * as mutationTypes from '@/store/mutations-types.js'
import { loginUrl } from '@/router/router.js'

/**
 * Class Errormanager
 */

class ErrorManager {
  /**
   * Cette fonction permet le traitement des erreurs des requêtes http faite avec fetch
   * @param {Object} response Il s'agit d'un objet de réponse à une requête http initié avec fetch
   * @param {Object} origin Cet objet contient les informations de la fonction dans laquelle s'est faite la requête http. L'objet contient le nom de la fonction et les paramètres qui lui avait été passés
   */
  async requestError(response, origin) {
    switch(response.status) {
    case 401:
      window.location.href = loginUrl
      break
    case 403:
      store.commit(mutationTypes.SET_ERROR, {
        type: errorTypes.TOO_MANY_CONCURRENT_USER,
        params: {}
      })
      break
    case 500:
      store.commit(mutationTypes.SET_ERROR, {
        type: errorTypes.SERVER,
        params: {
          origin
        }
      })
      break
    }
  }

  /**
   * Cette fonction est appelée lorsqu'une requête fetch fail (rentre dans le block catch). La fonction supprime l'access token du localstorage et redirige sur la page login ce qui aura pour effet de rediriger l'utilisateur sur la page de login du back. Actuellement, la fonction est surtout utile lorsqu'une révocation de certificat auto signé survient dans le back, l'utilisateur est ainsi redirigé sur la page de login du back et peut ré-autoriser le certificat.
   */
  fetchError(err) {
    if (err.name !== 'AbortError') { 
      window.location.href = loginUrl
    }
  }
}

export default new ErrorManager()